import { BsArrowClockwise, BsArrowRight, BsGear, BsGraphUp, BsList, BsPlayCircle, BsSpeedometer2, BsToggleOff, BsToggleOn, BsUnindent } from "react-icons/bs";
import { 
    GlobalContainer, 
    Header, 
    LogoImageHeader, 
    LogoImageHeaderSideBar, 
    Menu, 
    MenuItem, 
    MenuList, 
    SideBar, 
    TextInfo, 
    SideBarHeader, 
    TextDateValidation,
    ButtonLogOff, 
    ContainerButtonAnalysis,
    ButtonAnalysis,
    ContainerGoalsVertical,
    ItemGloalsVertical,
    ItemGloalsVerticalLg,
    ContainerGreenVertical,
    ItemGreenVertical,
    ItemGreenVerticalLg,
    ContainerGreenPercentegeVertical,
    ItemGreenPercentegeVerticalLg,
    ItemGreenPercentegeVertical,
    ContainerMinutes,
    ItemMinutes,
    ItemMinutesLg,
    ContainerOpacity,
    ContainerSettings,
    TextTitleSettings,
    ButtonSettings,
    TextTitleGreen,
    TextTitleRed,
    SiderBarSettings,
    SiderBarSettingsHeader,
    SiderBarSettingsButtonTransparentHeaderBack,
    SiderBarSettingsHeaderText,
    TextTitleItemSettings,
    ContainerHours,
    ItemHours,
    ItemGame,
    SideBarContent,
    SideBarTitle,
    SideBarBoxFlexBetween,
    SideBarItemShow,
    SideBarItemStrategy,
    SideBarIconCheck,
    BoxTable,
    SpaceSideBar,
    ButtonUpdate,
    ContainerHorasJogos,
    ButtomHour

} from "../styled";
import { Container } from '../../../componets/styles';
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from '../../../context/AuthContext';
import { formatarData } from '../../../share/functions';
import { GiSoccerBall } from "react-icons/gi";
import { getExpressCup } from "../requests/GET";
import TableAssemble from "../lib/TableAssemble";
import ParamContext from "../../../context/ParamContext";
import { SHOW, STRATEGYS } from "../constants";
import HeaderTableAssemble from "../lib/HeaderTableAssemble";
import Reload from "../../../componets/reload";

export default function TemplateDesktop({handlePage})
{
    const navigate = useNavigate();

    const {
        strategy, 
        setStrategy, 
        hourData, 
        setHourData, 
        showData, 
        setShowData,
        selectedScores, 
        setSelectedScores
    } = useContext(ParamContext);

    const [sideBar, setSideBar] = useState(false);
    const [sideBarSettings, setSideBarSettings] = useState(false);
    const [table, setTable] = useState([]);
    const {verifieldLogin, user, logout} = useContext(AuthContext);

 
    const [refresh, setRefresh] = useState(false)
    const [reload, setReload] = useState(false)

    const [rendered, setRendered] = useState({
        verticalData: [],
        hours: [],
        strategy: 'OVER 1.5',
        lasthours: `24`,
        green: '0%',
        red: '0%'
    })

    const handleItemClick = (scoreboard) => {
        setSelectedScores(prev => 
            prev.includes(scoreboard) ? prev.filter(score => score !== scoreboard) : [...prev, scoreboard]
        );
    };

    const getBgSelectColor = (scoreboard) => {
        const colors = [    
            '#0000FF', // Azul
            '#FFFF00', // Amarelo
            '#800080', // Roxo
            '#FFC0CB', // Rosa
            '#4B0082', // Índigo
            '#008080', // Verde-azulado
            '#FFA500', // Laranja
            '#00FFFF'  // Ciano
            ];
        const index = selectedScores.indexOf(scoreboard);
        return index !== -1 ? colors[index % colors.length] : null;
    };

    function changeShow(id)
    {
       setShowData(id);
    }

    function changeStrategy(id, name)
    {
        setStrategy(id)
        setRendered((prev) => ({
            ...prev,
            strategy: name,
        }));
    }

    const generateSlotsGolsVertical = (startMinute, step, length) => {
        return Array.from({ length: length }, (_, index) => {
          const minute = startMinute + index * step;
          return {
            id: minute / step + 1,
            goals: 0,
            minutes: minute.toString().padStart(2, '0'),
          };
        });
    };//Tem que fazer para cada linha


    async function handleExpressData(param)
    {

        setReload(true)

        const response = await getExpressCup(param);

        const dataHeaderTable = HeaderTableAssemble.create(response, strategy);

        setRendered((prev) => ({
            ...prev,
            green: dataHeaderTable.greensPercentage,
            red: dataHeaderTable.redsPercentage
        }));

        const tableAssemble = TableAssemble.create(response, strategy);

        console.log(tableAssemble)

        setRendered((prev => ({
            ...prev,
            verticalData: dataHeaderTable.resultsByMinute, 
            hours: tableAssemble
        })))

        setReload(false)
    }

    async function handleExpressDataRefresh(param)
    {

        const response = await getExpressCup(param);

        const dataHeaderTable = HeaderTableAssemble.create(response, strategy);

        setRendered((prev) => ({
            ...prev,
            green: dataHeaderTable.greensPercentage,
            red: dataHeaderTable.redsPercentage
        }));

        const tableAssemble = TableAssemble.create(response, strategy);

        setRendered((prev => ({
            ...prev,
            verticalData: dataHeaderTable.resultsByMinute, 
            hours: tableAssemble
        })))
    }

    useEffect(()=>{
       handleExpressData(hourData);
    }, [strategy, showData, refresh, hourData])

    useEffect(()=>{
        const interval = setInterval(() => {
            setRefresh(prev => !prev); // Alterna o valor de `refresh` a cada 60 segundos
            handleExpressDataRefresh(hourData)
        }, 60000); // 60000 milissegundos = 60 segundos

        return () => clearInterval(interval); 
    },[refresh])

    return(
        
        <GlobalContainer>

            {reload === true && <Reload bg='#000'/>} 

            <Header>

                {!sideBar ?  <LogoImageHeader src='./images/logo.png'/> : <div></div>}
               
                <Container mr="30px" w="auto" display={"flex"} jc="center" items={"center"}>
                    <TextInfo>Bem Vindo {user.name} | <TextDateValidation>{formatarData(user.validation)}</TextDateValidation></TextInfo>
                    <ButtonLogOff onClick={logout}>SAIR</ButtonLogOff>
                </Container>

                <Menu onClick={()=>{setSideBar(!sideBar)}}>
                    {sideBar === false ?   <BsList size={30} color="white" /> : <BsUnindent size={30} color="white" />}
                </Menu>

            </Header>


            <SiderBarSettings sideBarSettings={sideBarSettings}>

                {sideBarSettings &&
                <>
                
                <SiderBarSettingsHeader>
                    <SiderBarSettingsButtonTransparentHeaderBack onClick={()=>{setSideBarSettings(false)}}>
                        <BsArrowRight size={30} color="#fff"/>
                    </SiderBarSettingsButtonTransparentHeaderBack>

                    <SiderBarSettingsHeaderText>
                        Fechar
                    </SiderBarSettingsHeaderText>
                </SiderBarSettingsHeader>

                <SideBarContent>
{/**
 * 
 * 
 *                     <SideBarTitle>
                        Mostrar
                    </SideBarTitle>

                        {SHOW.map((item, index) =>{
                            return(
                                <SideBarBoxFlexBetween  key={index}>

                                    <SideBarItemStrategy key={index}>
                                        {item.name}
                                    </SideBarItemStrategy>

                                    <SideBarIconCheck onClick={(e)=>{changeShow(item.id)}}>
                                        {showData === item.id ? <BsToggleOn size={30} color={"#000"} /> : <BsToggleOff size={30} color={"#000"} />}
                                    </SideBarIconCheck>
                                    
                                </SideBarBoxFlexBetween>
                            )
                        })}
 * 
 */}
                    <SideBarTitle>
                        Horas de jogos
                    </SideBarTitle>

                    <ContainerHorasJogos>

                        <ButtomHour onClick={() => {
                            setHourData(6);
                            setRendered((prev) => ({ ...prev, lasthours: 6 }));
                        }} active={hourData === 6 ? true : false}>6</ButtomHour>

                        <ButtomHour onClick={() => {
                            setHourData(12);
                            setRendered((prev) => ({ ...prev, lasthours: 12 }));
                        }} active={hourData === 12 ? true : false}>12</ButtomHour>

                        <ButtomHour onClick={() => {
                            setHourData(23);
                            setRendered((prev) => ({ ...prev, lasthours: 24 }));
                        }} active={hourData === 23 ? true : false}>24</ButtomHour>

                    </ContainerHorasJogos>



       
                    <SideBarTitle>
                        Estratégias
                    </SideBarTitle>

                        {STRATEGYS.map((item, index) => {

                            return(
                                <SideBarBoxFlexBetween key={index}>

                                    <SideBarItemStrategy key={index}>
                                        {item.name}
                                    </SideBarItemStrategy>

                                    <SideBarIconCheck onClick={()=>{changeStrategy(item.id, item.name)}}>
                                        {strategy === item.id ? <BsToggleOn size={30} color={"#000"} /> : <BsToggleOff size={30} color={"#000"} />}
                                    </SideBarIconCheck>
                                    
                                </SideBarBoxFlexBetween>
                            )
                        })}               
                           
                    </SideBarContent>
                </>
                
                }

                <SpaceSideBar></SpaceSideBar>

            </SiderBarSettings>

            <SideBar sideBar={sideBar}>

                {sideBar &&
<>
                <SideBarHeader sideBar={sideBar}>
                    <LogoImageHeaderSideBar sideBar={sideBar}  src='./images/logo.png'/>
                </SideBarHeader>

                <MenuList>
                    <MenuItem onClick={()=>{navigate("/analysis")}}>
                        <BsGraphUp col='#fff' size={25}/>
                        Análises
                    </MenuItem>
                    <MenuItem onClick={()=>{navigate("/videos")}}>
                        <BsPlayCircle  col='#fff' size={30}/>
                        Vídeos
                    </MenuItem>
                </MenuList>
</>                    
                }

            </SideBar>

            <ContainerButtonAnalysis>

                <ButtonAnalysis onClick={()=>{handlePage("maximims")}}>MÁXIMAS</ButtonAnalysis>
                <ButtonAnalysis active={"true"}>EXPRESS CUP</ButtonAnalysis>
                <ButtonAnalysis onClick={()=>{handlePage("hour")}}>HORÁRIOS</ButtonAnalysis>
                <ButtonAnalysis onClick={()=>{handlePage("multihour")}}>MULTI HORÁRIOS</ButtonAnalysis>
                <ButtonAnalysis onClick={()=>{handlePage("confrontations")}}>CONFRONTOS</ButtonAnalysis>
                <ButtonAnalysis onClick={()=>{handlePage("ranking")}}>RANKING</ButtonAnalysis>

            </ContainerButtonAnalysis>

            <ContainerSettings>
                
                {/*<TextTitleSettings>MOSTRAR: <TextTitleItemSettings>FINAL DE JOGO</TextTitleItemSettings></TextTitleSettings>*/}

                <TextTitleSettings>ESTRATÉGIA: <TextTitleItemSettings>{rendered.strategy}</TextTitleItemSettings></TextTitleSettings>

                <TextTitleSettings>ÚLTIMAS HORAS: <TextTitleItemSettings>{rendered.lasthours} H</TextTitleItemSettings></TextTitleSettings>

                <TextTitleGreen>GREEN: {rendered.green}%</TextTitleGreen>

                <TextTitleRed>RED: {rendered.red}%</TextTitleRed>

                
                <ButtonUpdate onClick={()=>{setRefresh(!refresh)}}>
                    <BsArrowClockwise size={30} color={'#fff'} />
                </ButtonUpdate>

                <ButtonSettings onClick={()=>{setSideBarSettings(!sideBarSettings)}}>
                    <BsGear size={30} color={'#fff'} />
                </ButtonSettings>

            </ContainerSettings>

            <BoxTable>
                
                <ContainerGoalsVertical>

                    <ItemGloalsVerticalLg>G</ItemGloalsVerticalLg>

                    {rendered.verticalData.map((item, index) => {
                        return(
                            < ItemGloalsVertical key={index} >{item.totalGoals}</ItemGloalsVertical>
                        )
                    })}

                    <ItemGloalsVerticalLg></ItemGloalsVerticalLg>
                    <ItemGloalsVerticalLg></ItemGloalsVerticalLg>

                </ContainerGoalsVertical>

                <ContainerGreenVertical>

                    <ItemGreenVerticalLg></ItemGreenVerticalLg>

                    {rendered.verticalData.map((item, index) => {
                        return(
                            <ItemGreenVertical value={item.greensPercentage} key={index}>{item.totalGreens}</ItemGreenVertical>
                        )
                    })}

                    <ItemGreenVerticalLg></ItemGreenVerticalLg>
                    <ItemGreenVerticalLg></ItemGreenVerticalLg>

                </ContainerGreenVertical>

                
                <ContainerGreenPercentegeVertical>

                    <ItemGreenPercentegeVerticalLg></ItemGreenPercentegeVerticalLg>

                    {rendered.verticalData.map((item, index) => {
                        return(
                            <ItemGreenPercentegeVertical value={item.greensPercentage} key={index}>{item.greensPercentage}%</ItemGreenPercentegeVertical>
                        )
                    })}
                    <ItemGreenPercentegeVerticalLg></ItemGreenPercentegeVerticalLg>
                    <ItemGreenPercentegeVerticalLg></ItemGreenPercentegeVerticalLg>

                </ContainerGreenPercentegeVertical>

                <ContainerOpacity>

                    <ContainerMinutes>

                        <ItemMinutesLg>Hora</ItemMinutesLg>

                        {rendered.verticalData.map((item, index) => {
                            return(
                                <ItemMinutes key={index}>{item.minute}</ItemMinutes>
                            )
                        })}

                        <ItemMinutesLg>Dados</ItemMinutesLg>

                        <ItemMinutesLg>
                            <GiSoccerBall/>
                        </ItemMinutesLg>

                    </ContainerMinutes>

                    {rendered.hours.map((item, index) =>{
                        return(
                            <ContainerHours key={index}>
                                <ItemHours>{item.hour}</ItemHours>
                                {
                                    item.games.map((element, i)=>{
                                        if(element.code !== "summary")
                                        {
                                            return(
                                                <ItemGame 
                                                    key={i} 
                                                    color={element.color}
                                                    bgSelect={getBgSelectColor(element.scoreboard)}
                                                    onClick={() => handleItemClick(element.scoreboard)}
                                                >
                                                    {element.scoreboard}
                                                </ItemGame>
                                            )
                                        }

                                    })
                                }
                                <ItemGreenPercentegeVerticalLg value={item.greenPercentage}>{item.greenPercentage}%</ItemGreenPercentegeVerticalLg>
                                <ItemGreenPercentegeVerticalLg value={item.greenPercentage}>{item.totalGoals}</ItemGreenPercentegeVerticalLg>
                            </ContainerHours>
                        )
                    })}

                </ContainerOpacity>                

            </BoxTable>

        </GlobalContainer>
    )
}