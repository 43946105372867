
import { useContext, useEffect, useState } from 'react';
import {ButtonSide, GlobalContainer, Header, Sidebar, BoxHeaderGradient, BtnHourFilter, BtnOdds, ImageBandeira, BtnNextGames, ContainerCalc, BtnAmountGames, BtnGeralStatus, BtnStatusAway, Box, InputSearch, ContainerMobileHide, ContainerMobileShow, HeaderMobile, LogoMobile, SideBarMobile, ButtomMenu, BtnNextGamesMobile, ContainerMobileOverflow, BoxChampionshipHeader}  from './style';
import { BsList, BsSpeedometer2, BsGraphUp, BsPlayCircle, BsFillHandThumbsDownFill, BsFillHandThumbsUpFill, BsExclamationLg, BsXLg, BsTags } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, ImageLogo, Text } from '../../componets/styles';
import { MainContainer } from './style';
import { BoxChampionship } from './style';
import { BoxDropDown } from './style';
import { BtnChanpionship } from './style';
import { ImgChanpionship } from './style';
import { 
 
    API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES, 
    API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES, 
    API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES, 
    API_URL_SCRALPER_WORLD_CONFRONTATION,
    API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES} from '../../config';

import axios from 'axios';
import Reload from '../../componets/reload';
import ModalOdds from '../Modals/ModalOdds';
import AuthContext from '../../context/AuthContext';
import { formatarData } from '../../share/functions';


export default function Confrontations({handlePage})
{

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const [isSideOpen, setIsSideOpen] = useState(false);

    const [isBoxDropDown, setIsBoxDropDown] = useState(false);

    const [loop, setloop] = useState(false);

    const [lastKnownId, setLastKnownId] = useState("first");

    const [allResults, setAllResults] = useState([]);

    const [reload, setReload] = useState(false);

    const [modalOdds, setModalOdds] = useState(false);

    const [gameOddsSelect, setGameOddsSelect] = useState(null);

    const [dateOddsSelect, setDateOddsSelect] = useState(null);

    const [selectGame, setSelectGame] = useState({time:''});

    const [filterGames, setFilterGames] = useState(10);

    const [filter, setFilter] = useState(false);

    const [selectChampions, setSelectChampions] = useState({id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'})

    const [sideBarMobile, setSideBarMobile] = useState(false);

    const [nextGames, setNextGames] = useState([

        {id:1, time:"11:00"},
        {id:2, time:"11:03"},
        {id:3, time:"11:06"},
        {id:4, time:"11:09"},
        {id:5, time:"11:12"},
        {id:6, time:"11:15"}
    ])

    const [statistics, setStatistics] = useState([]);

    const [states, setStates] = useState({

        teamsHomeAndAwayStats:{

            lastGamesHomeAndAway:[],
            countGamesResultsGroupHomeOne: 0,
            countGamesResultsGroupHomeTwo: 0,
            countGamesResultsGroupHomeThree: 0,
            percentageGroupHomeOne: "0.00%",
            percentageGroupHomeTwo: "0.00%",
            percentageGroupHomeThree: "0.00%",
            countGamesResultsGroupAwayOne: 0,
            countGamesResultsGroupAwayTwo: 0,
            countGamesResultsGroupAwayThree: 0,
            percentageGroupAwayOne: "0.00%",
            percentageGroupAwayTwo: "0.00%",
            percentageGroupAwayThree: "0.00%",
            countResultTotalGoalsExact0Goals: 0,
            countResultTotalGoalsExact1Goals: 0,
            countResultTotalGoalsExact2Goals: 0,
            countResultTotalGoalsExact3Goals: 0,
            countResultTotalGoalsExact4Goals: 0,
            percentageResultTotalGoalsExact0Goals: "0.00%",
            percentageResultTotalGoalsExact1Goals: "0.00%",
            percentageResultTotalGoalsExact2Goals: "0.00%",
            percentageResultTotalGoalsExact3Goals: "0.00%",
            percentageResultTotalGoalsExact4Goals: "0.00%",

            countResultTotalGoalsExactHomeIsHome0Goals: 0,
            countResultTotalGoalsExactHomeIsHome1Goals: 0,
            countResultTotalGoalsExactHomeIsHome2Goals: 0,
            countResultTotalGoalsExactHomeIsHome3Goals: 0,
            countResultTotalGoalsExactHomeIsHome4Goals: 0,
            percentageResultTotalGoalsExactHomeIsHome0Goals: "0,00%",
            percentageResultTotalGoalsExactHomeIsHome1Goals: "0,00%",
            percentageResultTotalGoalsExactHomeIsHome2Goals: "0,00%",
            percentageResultTotalGoalsExactHomeIsHome3Goals: "0,00%",
            percentageResultTotalGoalsExactHomeIsHome4Goals: "0,00%",

            countResultTotalGoalsExactAwayIsHome0Goals: 0,
            countResultTotalGoalsExactAwayIsHome1Goals: 0,
            countResultTotalGoalsExactAwayIsHome2Goals: 0,
            countResultTotalGoalsExactAwayIsHome3Goals: 0,
            countResultTotalGoalsExactAwayIsHome4Goals: 0,
            percentageResultTotalGoalsExactAwayIsHome0Goals: "0,00%",
            percentageResultTotalGoalsExactAwayIsHome1Goals: "0,00%",
            percentageResultTotalGoalsExactAwayIsHome2Goals: "0,00%",
            percentageResultTotalGoalsExactAwayIsHome3Goals: "0,00%",
            percentageResultTotalGoalsExactAwayIsHome4Goals: "0,00%",

            countResultEndHome:0,
            countResultEndDraw:0,
            countResultEndAway:0,
            percentegeResultEndHome:"0,00%",
            percentegeResultEndDraw:"0,00%",
            percentegeResultEndAway:"0,00%",

            countGoalsHome:0,
            countGoalsAway:0,
            avaregeGoalsHome:0,
            avaregeGoalsAway:0,
            percentegeGoalsHome:"0,00%",
            percentegeGoalsAway:"0,00%",

            countScoreHomeYes:0,
            countScoreHomeNo:0,
            countScoreAwayYes:0,
            countScoreAwayNo:0,
            percentegeScoreHomeYes: "0,00%",
            percentegeScoreHomeNo: "0,00%",
            percentegeScoreAwayYes: "0,00%",
            percentegeScoreAwayNo: "0,00%",

            countOver15:0,
            countOver25:0,
            percentegeOver15: "0,00%",
            percentegeOver25: "0,00%",
            countUnder15:0,
            countUnder25:0,
            percentegeUnder15: "0,00%",
            percentegeUnder25: "0,00%",
            
            countBothTeamsToScore: 0,
            percentegeBothTeamsToScore: "0,00%",

            countHomeOrDraw: 0,
            countAwayOrDraw: 0,
            countHomeOrAway: 0,
            percentegeHomeOrDraw: "0,00%",
            percentegeAwayOrDraw: "0,00%",
            percentegeHomeOrAway: "0,00%",

            countMarginVitoryHomeOne: 0,
            countMarginVitoryHomeTwo: 0,
            countMarginVitoryHomeThree: 0,
            countMarginVitoryAwayOne: 0,
            countMarginVitoryAwayTwo: 0,
            countMarginVitoryAwayThree: 0,
            percentegeMarginVitoryHomeOne: "0,00%",
            percentegeMarginVitoryHomeTwo: "0,00%",
            percentegeMarginVitoryHomeThree: "0,00%",
            percentegeMarginVitoryAwayOne: "0,00%",
            percentegeMarginVitoryAwayTwo: "0,00%",
            percentegeMarginVitoryAwayThree: "0,00%",

            countHomeHT:0,
            countDrawHT:0,
            countAwayHT:0,

            percentegeHomeHT:"0,00%",
            percentegeDrawHT:"0,00",
            percentegeAwayHT:"0,00",

        },
        teamHomeStats:{
            lastGamesHomeAndAway:[],
            countGames:0,
            countGamesVictory:0,
            countGamesDraw:0,
            percentageVictory:0,
            percentageDraw:0,
            countTeamsBothScore:0,
            percentageTeamsBothScore:0,
            countTeamScore:0,
            percentageTeamScore:0,
            countOver05:0,
            countOver15:0,
            countOver25:0,
            countOver35:0,
            percentageOver05:0,
            percentageOver15:0,
            percentageOver25:0,
            percentageOver35:0,
            countUnder05:0,
            countUnder15:0,
            countUnder25:0,
            countUnder35:0,
            percentageUnder05:0,
            percentageUnder15:0,
            percentageUnder25:0,
            percentageUnder35:0
        },
        teamAwayStats:{
            lastGamesHomeAndAway:[],
            countGames:0,
            countGamesVictory:0,
            countGamesDraw:0,
            percentageVictory:0,
            percentageDraw:0,
            countTeamsBothScore:0,
            percentageTeamsBothScore:0,
            countTeamScore:0,
            percentageTeamScore:0,
            countOver05:0,
            countOver15:0,
            countOver25:0,
            countOver35:0,
            percentageOver05:0,
            percentageOver15:0,
            percentageOver25:0,
            percentageOver35:0,
            countUnder05:0,
            countUnder15:0,
            countUnder25:0,
            countUnder35:0,
            percentageUnder05:0,
            percentageUnder15:0,
            percentageUnder25:0,
            percentageUnder35:0           
        },
        teamHomeStatsHome:{
            lastGamesTeamGamingHome:[],
            countGames:0,
            countGamesVictory:0,
            countGamesDraw:0,
            percentageVictory:0,
            percentageDraw:0,
            countTeamsBothScore:0,
            percentageTeamsBothScore:0,
            countTeamScore:0,
            percentageTeamScore:0 
        },
        teamAwayStatsHome:{
            lastGamesTeamGamingHome:[],
            countGames:0,
            countGamesVictory:0,
            countGamesDraw:0,
            percentageVictory:0,
            percentageDraw:0,
            countTeamsBothScore:0,
            percentageTeamsBothScore:0,
            countTeamScore:0,
            percentageTeamScore:0 
        },
        teamHomeStatsAway:{
            countGames:0,
            countGamesVictory:0,
            countGamesDraw:0,
            percentageVictory:0,
            percentageDraw:0,
            countTeamsBothScore:0,
            percentageTeamsBothScore:0,
            countTeamScore:0,
            percentageTeamScore:0 
        },
        teamAwayStatsAway:{
            countGames:0,
            countGamesVictory:0,
            countGamesDraw:0,
            percentageVictory:0,
            percentageDraw:0,
            countTeamsBothScore:0,
            percentageTeamsBothScore:0,
            countTeamScore:0,
            percentageTeamScore:0 
        }

    });
    

    function handleGames(URL)
    {
        lastKnownId === "first" &&  setReload(true);

        axios.get(`${URL}${lastKnownId}`).then(response=>{

            console.log(response.data)

            setReload(false);

            setTimeout(() => {
                setloop(!loop);
            }, 100000);

        }).catch(error=>{

            console.log(error);
            setReload(false);
            setTimeout(() => {
                setloop(!loop);
            }, 100000);
        })
    }

    function handleNextGames(URL, champions)
    {
        setReload(true);

        axios.get(`${URL}`).then(response=>{

            const nextData = response.data.data

            const nextDataCopy = [...nextData]; // Cria uma cópia do array
            const nextaDataReverse = nextDataCopy.reverse();
            
            console.log(`Normal: `, nextData); // Ordem original
            console.log(`Reverse: `, nextaDataReverse); // Ordem invertida

            setNextGames(nextaDataReverse)

            setSelectGame(nextaDataReverse[0])

            handleStats(nextaDataReverse[0].team_home, nextaDataReverse[0].team_visit, champions);

            setTimeout(() => {
                setloop(!loop);
            }, 100000);

        }).catch(error=>{
            console.log(error);
            setReload(false);
            setTimeout(() => {
                setloop(!loop);
            }, 100000);
        })

    }

    async function handleStats(home, away, championsShip) {


     await  axios
          .get(`${API_URL_SCRALPER_WORLD_CONFRONTATION}?teamHome=${home}&teamAway=${away}&championsShip=${championsShip}&limit=${filterGames}`)
          .then((response) => {

            setReload(false);

            console.log(response.data);
      
            setStates((prevState) => ({
              ...prevState,
              
              teamsHomeAndAwayStats:{
                lastGamesHomeAndAway: response.data.TeamsHomeAndAwayStats.lastGamesHomeAndAway || [],
                countGamesResultsGroupHomeOne: response.data.TeamsHomeAndAwayStats.countGamesResultsGroupHomeOne || 0,
                countGamesResultsGroupHomeTwo: response.data.TeamsHomeAndAwayStats.countGamesResultsGroupHomeTwo || 0,
                countGamesResultsGroupHomeThree: response.data.TeamsHomeAndAwayStats.countGamesResultsGroupHomeThree || 0,
                percentageGroupHomeOne:  response.data.TeamsHomeAndAwayStats.percentageGroupHomeOne || "0.00%",
                percentageGroupHomeTwo:  response.data.TeamsHomeAndAwayStats.percentageGroupHomeTwo || "0.00%",
                percentageGroupHomeThree:  response.data.TeamsHomeAndAwayStats.percentageGroupHomeThree || "0.00%",
                countGamesResultsGroupAwayOne:  response.data.TeamsHomeAndAwayStats.countGamesResultsGroupAwayOne || 0,
                countGamesResultsGroupAwayTwo:  response.data.TeamsHomeAndAwayStats.countGamesResultsGroupAwayOne || 0,
                countGamesResultsGroupAwayThree:  response.data.TeamsHomeAndAwayStats.countGamesResultsGroupAwayOne || 0,
                percentageGroupAwayOne: response.data.TeamsHomeAndAwayStats.percentageGroupAwayOne || "0,00%",
                percentageGroupAwayTwo: response.data.TeamsHomeAndAwayStats.percentageGroupAwayTwo || "0,00%",
                percentageGroupAwayThree: response.data.TeamsHomeAndAwayStats.percentageGroupAwayThree || "0,00%",

                countResultTotalGoalsExact0Goals:  response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExact0Goals || 0,
                countResultTotalGoalsExact1Goals:  response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExact1Goals || 0,
                countResultTotalGoalsExact2Goals:  response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExact2Goals || 0,
                countResultTotalGoalsExact3Goals:  response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExact3Goals || 0,
                countResultTotalGoalsExact4Goals:  response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExact4Goals || 0,
                percentageResultTotalGoalsExact0Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExact0Goals ||  "0.00%",
                percentageResultTotalGoalsExact1Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExact1Goals ||  "0.00%",
                percentageResultTotalGoalsExact2Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExact2Goals ||  "0.00%",
                percentageResultTotalGoalsExact3Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExact3Goals ||  "0.00%",
                percentageResultTotalGoalsExact4Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExact4Goals ||  "0.00%",
    
                countResultTotalGoalsExactHomeIsHome0Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome0Goals || 0,
                countResultTotalGoalsExactHomeIsHome1Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome1Goals || 0,
                countResultTotalGoalsExactHomeIsHome2Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome2Goals || 0,
                countResultTotalGoalsExactHomeIsHome3Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome3Goals || 0,
                countResultTotalGoalsExactHomeIsHome4Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome4Goals || 0,
                percentageResultTotalGoalsExactHomeIsHome0Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome0Goals ||  "0.00%",
                percentageResultTotalGoalsExactHomeIsHome1Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome1Goals ||  "0.00%",
                percentageResultTotalGoalsExactHomeIsHome2Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome2Goals ||  "0.00%",
                percentageResultTotalGoalsExactHomeIsHome3Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome3Goals ||  "0.00%",
                percentageResultTotalGoalsExactHomeIsHome4Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome4Goals ||  "0.00%",
    
                countResultTotalGoalsExactAwayIsHome0Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome0Goals || 0,
                countResultTotalGoalsExactAwayIsHome1Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome1Goals || 0,
                countResultTotalGoalsExactAwayIsHome2Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome2Goals || 0,
                countResultTotalGoalsExactAwayIsHome3Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome3Goals || 0,
                countResultTotalGoalsExactAwayIsHome4Goals: response.data.TeamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome4Goals || 0,
                percentageResultTotalGoalsExactAwayIsHome0Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome0Goals ||  "0.00%",
                percentageResultTotalGoalsExactAwayIsHome1Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome1Goals ||  "0.00%",
                percentageResultTotalGoalsExactAwayIsHome2Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome2Goals ||  "0.00%",
                percentageResultTotalGoalsExactAwayIsHome3Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome3Goals ||  "0.00%",
                percentageResultTotalGoalsExactAwayIsHome4Goals: response.data.TeamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome4Goals ||  "0.00%",

                countResultEndHome:response.data.TeamsHomeAndAwayStats.countResultEndHome || 0,
                countResultEndDraw:response.data.TeamsHomeAndAwayStats.countResultEndDraw || 0,
                countResultEndAway:response.data.TeamsHomeAndAwayStats.countResultEndAway || 0,
                percentegeResultEndHome:response.data.TeamsHomeAndAwayStats.percentegeResultEndHome ||  "0.00%",
                percentegeResultEndDraw:response.data.TeamsHomeAndAwayStats.percentegeResultEndDraw ||  "0.00%",
                percentegeResultEndAway:response.data.TeamsHomeAndAwayStats.percentegeResultEndAway ||  "0.00%",
    
                countGoalsHome:response.data.TeamsHomeAndAwayStats.countGoalsHome || 0,
                countGoalsAway:response.data.TeamsHomeAndAwayStats.countGoalsAway || 0,
                avaregeGoalsHome:response.data.TeamsHomeAndAwayStats.avaregeGoalsHome || 0,
                avaregeGoalsAway:response.data.TeamsHomeAndAwayStats.avaregeGoalsAway || 0,
                percentegeGoalsHome:response.data.TeamsHomeAndAwayStats.percentegeGoalsHome ||  "0.00%",
                percentegeGoalsAway:response.data.TeamsHomeAndAwayStats.percentegeGoalsAway ||  "0.00%",
    
                countScoreHomeYes:response.data.TeamsHomeAndAwayStats.countScoreHomeYes || 0,
                countScoreHomeNo:response.data.TeamsHomeAndAwayStats.countScoreHomeNo || 0,
                countScoreAwayYes:response.data.TeamsHomeAndAwayStats.countScoreAwayYes || 0,
                countScoreAwayNo:response.data.TeamsHomeAndAwayStats.countScoreAwayNo || 0,
                percentegeScoreHomeYes: response.data.TeamsHomeAndAwayStats.percentegeScoreHomeYes ||  "0.00%",
                percentegeScoreHomeNo: response.data.TeamsHomeAndAwayStats.percentegeScoreHomeNo ||  "0.00%",
                percentegeScoreAwayYes: response.data.TeamsHomeAndAwayStats.percentegeScoreAwayYes ||  "0.00%",
                percentegeScoreAwayNo: response.data.TeamsHomeAndAwayStats.percentegeScoreAwayNo ||  "0.00%",

                countOver15:response.data.TeamsHomeAndAwayStats.countOver15 || 0,
                countOver25:response.data.TeamsHomeAndAwayStats.countOver25 || 0,
                percentegeOver15: response.data.TeamsHomeAndAwayStats.percentegeOver15 ||  "0.00%",
                percentegeOver25: response.data.TeamsHomeAndAwayStats.percentegeOver25 ||  "0.00%",
                countUnder15:response.data.TeamsHomeAndAwayStats.countUnder15 || 0,
                countUnder25:response.data.TeamsHomeAndAwayStats.countUnder25 || 0,
                percentegeUnder15: response.data.TeamsHomeAndAwayStats.percentegeUnder15 ||  "0.00%",
                percentegeUnder25: response.data.TeamsHomeAndAwayStats.percentegeUnder25 ||  "0.00%",

                countBothTeamsToScore: response.data.TeamsHomeAndAwayStats.countBothTeamsToScore || 0,
                percentegeBothTeamsToScore: response.data.TeamsHomeAndAwayStats.percentegeBothTeamsToScore ||  "0.00%",

                countHomeOrDraw: response.data.TeamsHomeAndAwayStats.countHomeOrDraw || 0,
                countAwayOrDraw: response.data.TeamsHomeAndAwayStats.countAwayOrDraw || 0,
                countHomeOrAway: response.data.TeamsHomeAndAwayStats.countHomeOrAway || 0,
                percentegeHomeOrDraw: response.data.TeamsHomeAndAwayStats.percentegeHomeOrDraw ||  "0.00%",
                percentegeAwayOrDraw: response.data.TeamsHomeAndAwayStats.percentegeAwayOrDraw ||  "0.00%",
                percentegeHomeOrAway: response.data.TeamsHomeAndAwayStats.percentegeHomeOrAway ||  "0.00%",

                countHomeHT: response.data.TeamsHomeAndAwayStats.countHomeHT ||  0,
                countDrawHT: response.data.TeamsHomeAndAwayStats.countDrawHT ||  0,
                countAwayHT: response.data.TeamsHomeAndAwayStats.countAwayHT ||  0,
    
                percentegeHomeHT: response.data.TeamsHomeAndAwayStats.percentegeHomeHT ||  "0.00%",
                percentegeDrawHT: response.data.TeamsHomeAndAwayStats.percentegeDrawHT ||  "0.00%",
                percentegeAwayHT: response.data.TeamsHomeAndAwayStats.percentegeAwayHT ||  "0.00%",

                countMarginVitoryHomeOne:  response.data.TeamsHomeAndAwayStats.countMarginVitoryHomeOne || 0,
                countMarginVitoryHomeTwo: response.data.TeamsHomeAndAwayStats.countMarginVitoryHomeTwo || 0,
                countMarginVitoryHomeThree: response.data.TeamsHomeAndAwayStats.countMarginVitoryHomeThree || 0,
                countMarginVitoryAwayOne: response.data.TeamsHomeAndAwayStats.countMarginVitoryAwayOne || 0,
                countMarginVitoryAwayTwo: response.data.TeamsHomeAndAwayStats.countMarginVitoryAwayTwo || 0,
                countMarginVitoryAwayThree: response.data.TeamsHomeAndAwayStats.countMarginVitoryAwayThree || 0,
                percentegeMarginVitoryHomeOne: response.data.TeamsHomeAndAwayStats.formattedPercentegeMarginVitoryHomeOne || "0,00%",
                percentegeMarginVitoryHomeTwo: response.data.TeamsHomeAndAwayStats.formattedPercentegeMarginVitoryHomeTwo || "0,00%",
                percentegeMarginVitoryHomeThree: response.data.TeamsHomeAndAwayStats.formattedPercentegeMarginVitoryHomeThree || "0,00%",
                percentegeMarginVitoryAwayOne: response.data.TeamsHomeAndAwayStats.formattedPercentegeMarginVitoryAwayOne || "0,00%",
                percentegeMarginVitoryAwayTwo: response.data.TeamsHomeAndAwayStats.formattedPercentegeMarginVitoryAwayTwo || "0,00%",
                percentegeMarginVitoryAwayThree: response.data.TeamsHomeAndAwayStats.formattedPercentegeMarginVitoryAwayThree || "0,00%",

                },
                teamHomeStats: {
                lastGamesHomeAndAway: response.data.TeamHomeStats.lastGamesHomeAndAway || [],
                countGames: response.data.TeamHomeStats.countGames || 0,
                countGamesVictory: response.data.TeamHomeStats.countGamesVictory || 0,
                countGamesDraw: response.data.TeamHomeStats.countGamesDraw || 0,
                percentageVictory: response.data.TeamHomeStats.percentageVictory || 0,
                percentageDraw: response.data.TeamHomeStats.percentageDraw || 0,
                countTeamsBothScore: response.data.TeamHomeStats.countTeamsBothScore || 0,
                percentageTeamsBothScore: response.data.TeamHomeStats.percentageTeamsBothScore || 0,
                countTeamScore: response.data.TeamHomeStats.countTeamScore || 0,
                percentageTeamScore: response.data.TeamHomeStats.percentageTeamScore || 0,
                countOver05: response.data.TeamHomeStats.countOver05|| 0,
                countOver15: response.data.TeamHomeStats.countOver15|| 0,
                countOver25: response.data.TeamHomeStats.countOver25|| 0,
                countOver35: response.data.TeamHomeStats.countOver35|| 0,
                percentageOver05: response.data.TeamHomeStats.percentageOver05|| 0,
                percentageOver15: response.data.TeamHomeStats.percentageOver15|| 0,
                percentageOver25: response.data.TeamHomeStats.percentageOver25|| 0,
                percentageOver35: response.data.TeamHomeStats.percentageOver35|| 0,
                countUnder05: response.data.TeamHomeStats.countUnder05|| 0,
                countUnder15: response.data.TeamHomeStats.countUnder15|| 0,
                countUnder25: response.data.TeamHomeStats.countUnder25|| 0,
                countUnder35: response.data.TeamHomeStats.countUnder35|| 0,
                percentageUnder05: response.data.TeamHomeStats.percentageUnder05|| 0,
                percentageUnder15: response.data.TeamHomeStats.percentageUnder15|| 0,
                percentageUnder25: response.data.TeamHomeStats.percentageUnder25|| 0,
                percentageUnder35: response.data.TeamHomeStats.percentageUnder35|| 0,
              },
              teamAwayStats: {
                lastGamesHomeAndAway: response.data.TeamAwayStats.lastGamesHomeAndAway || [],
                countGames: response.data.TeamAwayStats.countGames || 0,
                countGamesVictory: response.data.TeamAwayStats.countGamesVictory || 0,
                countGamesDraw: response.data.TeamAwayStats.countGamesDraw || 0,
                percentageVictory: response.data.TeamAwayStats.percentageVictory || 0,
                percentageDraw: response.data.TeamAwayStats.percentageDraw || 0,
                countTeamsBothScore: response.data.TeamAwayStats.countTeamsBothScore || 0,
                percentageTeamsBothScore: response.data.TeamAwayStats.percentageTeamsBothScore || 0,
                countTeamScore: response.data.TeamAwayStats.countTeamScore || 0,
                percentageTeamScore: response.data.TeamAwayStats.percentageTeamScore || 0,
                countOver05: response.data.TeamAwayStats.countOver05 || 0,
                countOver15: response.data.TeamAwayStats.countOver15 || 0,
                countOver25: response.data.TeamAwayStats.countOver25 || 0,
                countOver35: response.data.TeamAwayStats.countOver35 || 0,
                percentageOver05: response.data.TeamAwayStats.percentageOver05|| 0,
                percentageOver15: response.data.TeamAwayStats.percentageOver15|| 0,
                percentageOver25: response.data.TeamAwayStats.percentageOver25|| 0,
                percentageOver35: response.data.TeamAwayStats.percentageOver35|| 0,
                countUnder05: response.data.TeamAwayStats.countUnder05 || 0,
                countUnder15: response.data.TeamAwayStats.countUnder15 || 0,
                countUnder25: response.data.TeamAwayStats.countUnder25 || 0,
                countUnder35: response.data.TeamAwayStats.countUnder35 || 0,
                percentageUnder05: response.data.TeamAwayStats.percentageUnder05|| 0,
                percentageUnder15: response.data.TeamAwayStats.percentageUnder15|| 0,
                percentageUnder25: response.data.TeamAwayStats.percentageUnder25|| 0,
                percentageUnder35: response.data.TeamAwayStats.percentageUnder35|| 0,
              },
              teamHomeStatsHome: {
                lastGamesTeamGamingHome: response.data.TeamHomeStatsHome.lastGamesTeamGamingHome || [],
                countGames: response.data.TeamHomeStatsHome.countGames || 0,
                countGamesVictory: response.data.TeamHomeStatsHome.countGamesVictory || 0,
                countGamesDraw: response.data.TeamHomeStatsHome.countGamesDraw || 0,
                percentageVictory: response.data.TeamHomeStatsHome.percentageVictory || 0,
                percentageDraw: response.data.TeamHomeStatsHome.percentageDraw || 0,
                countTeamsBothScore: response.data.TeamHomeStatsHome.countTeamsBothScore || 0,
                percentageTeamsBothScore: response.data.TeamHomeStatsHome.percentageTeamsBothScore || 0,
                countTeamScore: response.data.TeamHomeStatsHome.countTeamScore || 0,
                percentageTeamScore: response.data.TeamHomeStatsHome.percentageTeamScore || 0,
              },
              teamAwayStatsHome: {
                lastGamesTeamGamingHome: response.data.TeamHomeStatsHome.lastGamesTeamGamingHome || [],
                countGames: response.data.TeamAwayStatsHome.countGames || 0,
                countGamesVictory: response.data.TeamAwayStatsHome.countGamesVictory || 0,
                countGamesDraw: response.data.TeamAwayStatsHome.countGamesDraw || 0,
                percentageVictory: response.data.TeamAwayStatsHome.percentageVictory || 0,
                percentageDraw: response.data.TeamAwayStatsHome.percentageDraw || 0,
                countTeamsBothScore: response.data.TeamAwayStatsHome.countTeamsBothScore || 0,
                percentageTeamsBothScore: response.data.TeamAwayStatsHome.percentageTeamsBothScore || 0,
                countTeamScore: response.data.TeamAwayStatsHome.countTeamScore || 0,
                percentageTeamScore: response.data.TeamAwayStatsHome.percentageTeamScore || 0,
              },
              teamHomeStatsAway: {
                countGames: response.data.TeamHomeStatsAway.countGames || 0,
                countGamesVictory: response.data.TeamHomeStatsAway.countGamesVictory || 0,
                countGamesDraw: response.data.TeamHomeStatsAway.countGamesDraw || 0,
                percentageVictory: response.data.TeamHomeStatsAway.percentageVictory || 0,
                percentageDraw: response.data.TeamHomeStatsAway.percentageDraw || 0,
                countTeamsBothScore: response.data.TeamHomeStatsAway.countTeamsBothScore || 0,
                percentageTeamsBothScore: response.data.TeamHomeStatsAway.percentageTeamsBothScore || 0,
                countTeamScore: response.data.TeamHomeStatsAway.countTeamScore || 0,
                percentageTeamScore: response.data.TeamHomeStatsAway.percentageTeamScore || 0,
              },
              teamAwayStatsAway: {
                countGames: response.data.TeamAwayStatsAway.countGames || 0,
                countGamesVictory: response.data.TeamAwayStatsAway.countGamesVictory || 0,
                countGamesDraw: response.data.TeamAwayStatsAway.countGamesDraw || 0,
                percentageVictory: response.data.TeamAwayStatsAway.percentageVictory || 0,
                percentageDraw: response.data.TeamAwayStatsAway.percentageDraw || 0,
                countTeamsBothScore: response.data.TeamAwayStatsAway.countTeamsBothScore || 0,
                percentageTeamsBothScore: response.data.TeamAwayStatsAway.percentageTeamsBothScore || 0,
                countTeamScore: response.data.TeamAwayStatsAway.countTeamScore || 0,
                percentageTeamScore: response.data.TeamAwayStatsAway.percentageTeamScore || 0,
              },
            }));
          })
          .catch((error) => {
            console.log(error);
            setReload(false);
          });
      }
      
      
    async function handleFilterGames()
    {
        setFilter(true);
        setReload(true);
        await handleStats(selectGame.team_home, selectGame.team_visit, selectChampions.name);
        setReload(false);
    }

    function clearFilter()
    {
        setFilter(false);
    }

    useEffect(()=>{

        handleNextGames(API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES, 'Copa do mundo');

    },[])


    const CHAMPIONS_SHIP = [

        {id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'},
        {id:2, name: 'Euro copa', img:'./images/euro-copa.png', margin:'10px'},
        {id:3, name: 'Super Liga Super Americana', img:'./images/conmebol-sul-americana.png', margin:'10px'},
        {id:4, name: 'Premier league', img:'./images/premier-league.png', margin:'10px'}
    ]

    const navigate = useNavigate();


    function visibleDrop() {
        setIsBoxDropDown(prev => !prev);
    }


    function limitCharacters(input, maxLength) {
        if (input.length <= maxLength) {
          return input;
        }
        return input.substring(0, maxLength) + "...";
    }

    function handleChampionsShip(item)
    {
        setFilter(false);
        setLastKnownId("first");
        setSelectChampions(item)
        setIsBoxDropDown(false);

        switch (item.id) {

            case 1:
                handleNextGames(API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES, 'Copa do mundo');
                break;
            case 2:
                handleNextGames(API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES, 'Euro copa');
                break;
            case 3:
                handleNextGames(API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES, 'Super Liga Super Americana');
                break;  
            case 4:
                handleNextGames(API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES, 'Premier league');
                break;  
            default:
                break;
        }

    }

    function handleGame(item)
    {
        setSelectGame(item)

        handleStats(item.team_home, item.team_visit, selectChampions.name);
    }



    function convertDateTimeToMinutesSeconds(data) {
        // Extraia a hora e minuto de time
        const [hours, minutes] = data.time.split(':');
      
        // Atualize date_time com a data original e a hora e minuto de time
        const [datePart] = data.date_time.split(' ');
        const newDateTime = `${datePart} ${hours}:${minutes}`;
      
        // Retorne o objeto atualizado
        return {
          time: data.time,
          date_time: newDateTime,
          team_home: data.team_home,
          team_away: data.team_away,
          scoreboard: data.scoreboard,
          resultEnd: data.resultEnd,
        };
      }
      
    function bgBadge(percentageStr) {

        if (percentageStr) {

            const percentage = parseFloat(percentageStr.replace('%', ''));

            if (percentage < 50) {
                return "#FECACA";
            } else if (percentage >= 50 && percentage <= 60) {
                return "#FEF3C7";
            } else {
                return "#A7F3D0";
            }
          }

          return "#0000";
    }

    function iconBadge(percentageStr) {
        if(percentageStr)
        {
            const percentage = parseFloat(percentageStr.replace('%', ''));

            if (percentage < 50) {
                return <BsFillHandThumbsDownFill style={{fontSize:"20px", color:"#EF4444"}}/>
            } else if (percentage >= 50 && percentage <= 60) {
                return <BsFillHandThumbsUpFill style={{fontSize:"20px", color:"#F59E0B"}}/>
            } else {
                return <BsFillHandThumbsUpFill style={{fontSize:"20px", color:"#047857"}}/>
            }
        }

        return <BsFillHandThumbsDownFill style={{fontSize:"20px", color:"#EF4444"}}/>
    }

    function textBadge(percentageStr) {

        if(percentageStr)
        {
            const percentage = parseFloat(percentageStr.replace('%', ''));

            if (percentage < 50) {
                return "#EF4444";
            } else if (percentage >= 50 && percentage <= 60) {
                return "#F59E0B";
            } else {
                return "#047857";
            }
        }

        return "#EF4444";
    }




    return(
        <GlobalContainer>

           {reload === true && <Reload/>} 

            <ContainerMobileHide>


                <Header>

                    <Container display={'flex'}  items="center"  w="50%" h="100%" ml="50px">

                        <ImageLogo src='./images/logo.png'/>

                        <BoxChampionshipHeader onClick={visibleDrop}>
                            <Container display={'flex'}  items="center" jc="center">
                                <ImgChanpionship src={selectChampions.img} />
                                <Text fw="bold" fs="20px" col='#fff'>{limitCharacters(selectChampions.name, 15)}</Text>
                            </Container>
                        </BoxChampionshipHeader>


                        <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                            {
                                CHAMPIONS_SHIP.map(item => (
                                    isBoxDropDown &&
                                    <Container key={item.id} mt={item.margin}>
                                        <BtnChanpionship onClick={() => handleChampionsShip(item)}>
                                            <ImgChanpionship src={item.img} />
                                            {item.name}
                                        </BtnChanpionship>
                                    </Container>
                                ))
                            }
                        </BoxDropDown>

                    </Container>

                        <Container w='auto' display='flex' mr="20px" items='center'>
                            <Container w='auto' display='flex' mr="20px" >
                                <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{`${formatarData(user.validation)}`}</Text>
                            </Container>


                            <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                                <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                            </ButtonStyled>
                        </Container>

                    </Header>

                    <Sidebar open={isSideOpen}>

                        <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                            <BsList col='#fff' size={45}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                        </ButtonSide>
{/** 
                        <ButtonSide open={isSideOpen}  mt="50px" onClick={()=>{navigate("/home")}}>
                            <BsSpeedometer2 col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                            
                        </ButtonSide>
*/}
                        <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/analysis")}}>
                            <BsGraphUp col='#fff' size={33}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                        </ButtonSide>

                        <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate("/videos")}}>
                            <BsPlayCircle  col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                        </ButtonSide>
{/*
                        <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate("/robos")}}>
                            <BsTags  col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Ofertas</Text>}
                        </ButtonSide>
* */}
                    </Sidebar>

                    <MainContainer >

                    <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display={"flex"} jc="space-between">
                        
                        <ButtonStyled w="16.2%" bg='#15803d' onClick={()=>{handlePage("maximims")}}>
                            <Text col="#fff" fw="bold" fs="25px">MÁXIMAS</Text>
                        </ButtonStyled>
  
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("expressCup")}}>
                            <Text col="#fff" fw="bold" fs="25px">EXPRESS CUP</Text>
                        </ButtonStyled>
  
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("hour")}}>
                            <Text col="#fff" fw="bold" fs="25px">HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("multihour")}}>
                            <Text col="#fff" fw="bold" fs="25px">MULTI HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("confrontations")}}>
                            <Text col="#fff" fw="bold" fs="25px">CONFRONTOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%" onClick={()=>{handlePage("ranking")}}>
                            <Text col="#fff" fw="bold" fs="25px">RANKING</Text>
                        </ButtonStyled>                     

                    </Container>

                            
                        <Container w="98%" display="flex" pl="20px" pr="20px">

                            <Container w="200px" display="flex" fd="column" jc="center" items="center">

                                {nextGames.map((item, index)=>{
                                    return(
                                        <BtnNextGames onClick={()=>{handleGame(item)}} bg={selectGame.time === item.time ? "#316227" : "#4f9d5d"} key={index} mt="20px">
                                            <Text col="#fff" fw="bold">{item.time}</Text>
                                        </BtnNextGames>
                                    )
                                })}

                            </Container>

                            <ContainerCalc wc="200px" bg="#316227">

                                <Container w="100%" display ="flex" jc="space-between" items="center">

                                    <Container w="auto" mr="20px">

                                        <ImageBandeira w="100px" h="80px" src={`./bandeiras/${selectGame.team_home}.png`}/>

                                        <Container display="flex" jc="center" items="center" >
                                            <Text col="#fff" fw="bold">{selectGame.team_home}</Text>
                                        </Container>

                                    </Container>
        {/**
         *                            <BtnAmountGames>
                                        <Text fs="20px" fw="bold" col="#316227">15</Text>
                                    </BtnAmountGames>

                                    <BtnGeralStatus>
                                        <Text fs="20px" fw="bold" col="#316227">Status Geral</Text>
                                    </BtnGeralStatus>

                                    <BtnStatusAway>
                                        <Text fs="20px" fw="bold" col="#316227">Status Visitante</Text>
                                    </BtnStatusAway>
        * 
        * 
        */}
                                    <Container w="auto">
                                        <Text col="#fff" fs="40px" fw="bold">Resultados de confrontos</Text>
                                    </Container>


                                    <Container w="auto" mr="20px">

                                            <ImageBandeira w="100px" h="80px" src={`./bandeiras/${selectGame.team_visit}.png`}/>

                                            <Container display="flex" jc="center" items="center" >
                                                <Text col="#fff" fw="bold">{selectGame.team_visit}</Text>
                                            </Container>
                                        
                                    </Container>

                                </Container> 

                                <BoxHeaderGradient mt="50px">
                                    <Text fs="30px" col="#316227" fw="bold">Confrontos diretos</Text>
                                </BoxHeaderGradient>

                                <Container bg="#fff" mt="20px" h="80px" display="flex" jc="space-between" items="center">
                                    
                                    <Text ml="20px" fs="30px">Filtrar quantidade de jogos</Text>
                                    
                                    <Container display="flex" w="750px" items="center">

                                        <InputSearch onChange={(e)=>{setFilterGames(e.target.value)}} value={filterGames} placeholder="Digite a quantidade de jogos"/>
                                        <ButtonStyled onClick={()=>{handleFilterGames()}} w="120px"  fs="20px" ml="10px">
                                            <Text fs="20px" col="#fff">Filtrar</Text>
                                        </ButtonStyled>
                                        {
                                            filter &&

                                            <ButtonStyled bg="#F59E0B" onClick={()=>{clearFilter()}} w="120px"  fs="20px" ml="10px">
                                                <Text fs="20px" col="#fff">Limpar</Text>
                                            </ButtonStyled>
                                        }

                                    </Container>
                                    
                                </Container>

                                <Container display="flex" jc="space-between" mt="20px">
                                    <Container w="33%">

                                    <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_home} FT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countResultEndHome}</Text>
                                            </Container>
                                        
                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeResultEndHome)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeResultEndHome)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeResultEndHome)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeResultEndHome}</Text>
                                                </Container>

                                            </Container>

                                        </Container>

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Empate FT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countResultEndDraw}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeResultEndDraw)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeResultEndDraw)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeResultEndDraw)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeResultEndDraw}</Text>
                                                </Container>

                                            </Container>

                                        </Container>

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_visit}`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countResultEndAway}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeResultEndAway)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeResultEndAway)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeResultEndAway)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeResultEndAway}</Text>
                                                </Container>

                                            </Container>

                                        </Container>

                                        <Container w="100%" bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Gols ${selectGame.team_home}`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countGoalsHome}</Text>
                                            </Container>
                                        

                                            <Container w="25%" h="100%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px" fw="bold">{`Média de ${states.teamsHomeAndAwayStats.avaregeGoalsHome} gols`}</Text>
                                            </Container>

                                        </Container>

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Gols ${selectGame.team_visit}`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countGoalsAway}</Text>
                                            </Container>
                                        

                                            <Container w="25%" h="100%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px" fw="bold">{`Média de ${states.teamsHomeAndAwayStats.avaregeGoalsAway} gols`}</Text>
                                            </Container>

                                        </Container>  


                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Casa SIM`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countScoreHomeYes}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreHomeYes}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center"  >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Casa NÂO`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countScoreHomeNo}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeNo)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeNo)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeNo)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreHomeNo}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>  

                                        {/* end container */}
                                    </Container>

                                    <Container w="33%">

                                    <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Over 1.5`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countOver15}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeOver15)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeOver15)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeOver15)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeOver15}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Over 2.5`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countOver25}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeOver25)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeOver25)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeOver25)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeOver25}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Under 1.5`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countUnder15}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeUnder15)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeUnder15)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeUnder15)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeUnder15}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Under 2.5`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countUnder25}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeUnder25)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeUnder25)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeUnder25)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeUnder25}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Ambas Marcam`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countBothTeamsToScore}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeBothTeamsToScore)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeBothTeamsToScore)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeBothTeamsToScore)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeBothTeamsToScore}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} marca gol`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countScoreHomeYes}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreHomeYes}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} marca gol`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countScoreAwayYes}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreAwayYes)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreAwayYes)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreAwayYes)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreAwayYes}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>  
                                    </Container>

                                    <Container w="33%">

                                        <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} ou Draw`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countHomeOrDraw}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeHomeOrDraw)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeHomeOrDraw)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeHomeOrDraw)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeHomeOrDraw}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} ou Draw`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countAwayOrDraw}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeAwayOrDraw)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeAwayOrDraw)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeAwayOrDraw)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeAwayOrDraw}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} ou ${selectGame.team_visit}`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countHomeOrAway}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeHomeOrAway)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeHomeOrAway)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeHomeOrAway)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeHomeOrAway}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} HT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countHomeHT}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeHomeHT)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeHomeHT)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeHomeHT)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeHomeHT}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>  

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`Draw HT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countDrawHT}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeDrawHT)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeDrawHT)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeDrawHT)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeDrawHT}</Text>
                                                </Container>

                                            </Container>

                                        </Container>  

                                        <Container bg="#064E3B" h="10px"></Container>    

                                        <Container bg="#064E3B" h="50px" display="flex" jc="space-between" items="center" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} HT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamsHomeAndAwayStats.countAwayHT}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeAwayHT)} h="40px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeAwayHT)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeAwayHT)} fs="20px" fw="bold">{states.teamsHomeAndAwayStats.percentegeAwayHT}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="10px"></Container>                                                                  

                                    </Container>

                                </Container>

                                <Container mt="20px">

                                </Container>   

        {/**                     
                             
                                

                                <Container mt="5px" display="flex" jc="space-between">

                                <Container w="49.9%" >

                                    <Container bg="#707070" h="60px" display="flex" jc="center" items="center">
                                        <Text col="#fff" fs="30px" fw="bold">Resultado grupo {selectGame.team_home}</Text>
                                    </Container>


                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">{selectGame.team_home} 1x0 - 2x0 - 2x1</Text>
                                        </Container>

                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countGamesResultsGroupHomeOne}</Text>
                                        </Container>

                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageGroupHomeOne}</Text>
                                        </Container>
                                        
                                    </Container>


                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">{selectGame.team_home} 3x0 - 3x1 - 4x0</Text>
                                        </Container>

                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countGamesResultsGroupHomeTwo}</Text>
                                        </Container>

                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageGroupHomeTwo}</Text>
                                        </Container>

                                    </Container>


                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">{selectGame.team_home} 0x0</Text>
                                        </Container>

                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countGamesResultsGroupHomeThree}</Text>
                                        </Container>

                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageGroupHomeThree}</Text>
                                        </Container>
                                        
                                    </Container>

                                    <Container bg="#707070" h="60px" display="flex" jc="center" items="center" mt="5px">
                                        <Text col="#fff" fs="30px" fw="bold">Gols exatos na partida</Text>
                                    </Container>


                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">0 Gols</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExact0Goals}</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExact0Goals}</Text>
                                        </Container>
                                        
                                    </Container>


                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">1 Gols</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExact1Goals}</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExact1Goals}</Text>
                                        </Container>
                                        
                                    </Container> 

                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">2 Gols</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExact2Goals}</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExact2Goals}</Text>
                                        </Container>

                                    </Container> 

                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">3 Gols</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExact3Goals}</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExact3Goals}</Text>
                                        </Container>
                                        
                                    </Container> 

                                    <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                        <Container w="40%" display="flex" jc="start" items="center">
                                            <Text ml="15px" col="#fff" fs="20px" fw="">4 Gols</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExact4Goals}</Text>
                                        </Container>
                                        <Container w="30%" display="flex" jc="end" items="center">
                                            <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExact4Goals}</Text>
                                        </Container>

                                    </Container> 

                                </Container>


                                <Container w="49.9%" >

                                        <Container bg="#707070" h="60px" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="30px" fw="bold">Resultado grupo {selectGame.team_visit}</Text>
                                        </Container>


                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                            <Container w="40%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw="">{selectGame.team_visit} 1x0 - 2x0 - 2x1</Text>
                                            </Container>

                                            <Container w="30%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countGamesResultsGroupAwayOne}</Text>
                                            </Container>

                                            <Container w="30%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageGroupAwayOne}</Text>
                                            </Container>
                                            
                                        </Container>


                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                            <Container w="40%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw="">{selectGame.team_visit} 3x0 - 3x1 - 4x0</Text>
                                            </Container>
                                            <Container w="30%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countGamesResultsGroupAwayTwo}</Text>
                                            </Container>

                                            <Container w="30%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageGroupAwayTwo}</Text>
                                            </Container>

                                        </Container>


                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                            <Container w="40%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw="">{selectGame.team_visit} 0x0</Text>
                                            </Container>

                                            <Container w="30%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countGamesResultsGroupAwayThree}</Text>
                                            </Container>

                                            <Container w="30%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageGroupAwayThree}</Text>
                                            </Container>
                                            
                                        </Container>

                                    

                                        <Container bg="#707070" h="60px" display="flex" jc="center" items="center" mt="5px">

                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="30px" fw="bold">Gols exatos {selectGame.team_home}</Text>
                                            </Container>

                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="30px" fw="bold">Gols exatos {selectGame.team_visit}</Text>
                                            </Container>
                                            
                                        </Container>


                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="3px">

                                            <Container w="30%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw=""> 0 Gols</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome0Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome0Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome0Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome0Goals}</Text>
                                            </Container> 

                                        </Container>




                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                            <Container w="30%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw=""> 1 Gols</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome0Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome1Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome1Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome1Goals}</Text>
                                            </Container> 

                                        </Container>


                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                            <Container w="30%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw="">2 Gols</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome2Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome2Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome2Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome2Goals}</Text>
                                            </Container> 

                                        </Container>   

                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                            <Container w="30%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw="">3 Gols</Text>
                                            </Container>

                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome3Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome3Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome3Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome3Goals}</Text>
                                            </Container> 

                                        </Container>  

                                        <Container bg="#707070" h="60px" display="flex" jc="space-between" items="center" mt="2px">

                                            <Container w="30%" display="flex" jc="start" items="center">
                                                <Text ml="15px" col="#fff" fs="20px" fw="">4 Gols</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactHomeIsHome4Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactHomeIsHome4Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.countResultTotalGoalsExactAwayIsHome4Goals}</Text>
                                            </Container>
                                            <Container w="15%" display="flex" jc="end" items="center">
                                                <Text mr="15px" col="#fff" fs="20px" fw="">{states.teamsHomeAndAwayStats.percentageResultTotalGoalsExactAwayIsHome4Goals}</Text>
                                            </Container> 

                                        </Container>                                       

                                </Container>

                                </Container>    


                            */}
                                <Container w="100%" mt="20px">
                                    <Text col="#fff" fs="40px" >Resultados de confrontos indiretos</Text>
                                </Container>


                                <Container display="flex" jc="space-between" items="center" mt="20px" >
                                
                                    <Container w="49.5%" >

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center" >
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Total de jogos ${selectGame.team_home}`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countGames}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>    

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                        
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="18px">{`Total de jogos em casa ${selectGame.team_home}`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countGames}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                    {/**SPACE */}

                                    <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                        
                                        <Container w="50%" >
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="18px">{`Total de jogos fora casa ${selectGame.team_home}`}</Text>
                                            </Container>
                                        </Container>

                                        <Container w="30%">
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countGames}</Text>
                                            </Container>
                                        </Container>

                                        <Container w="20%">
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                            </Container>                           
                                        </Container>

                                    </Container>   


                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_home} FT`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empates FT`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageDraw)> 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>    

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitórias ${selectGame.team_home} em casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsHome.percentageVictory)> 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStatsHome.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_home} fora casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsAway.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStatsAway.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_home} em casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageDraw )> 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStatsHome.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  


                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_home} fora casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStatsAway.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Ambas marcam`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countTeamsBothScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageTeamsBothScore) > 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStats.percentageTeamsBothScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} marca`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countTeamScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageTeamScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} jogando em casa marca`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countTeamScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsHome.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStatsHome.percentageTeamScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} jogando fora de casa marca`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countTeamScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsAway.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamHomeStatsAway.percentageTeamScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 0.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver05}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageOver05}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 1.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver15}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver15) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageOver15}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 2.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver25}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver25) > 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStats.percentageOver25}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 3.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver35}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver35) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageOver35}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 0.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder05}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageUnder05}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 1.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder15}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder15) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageUnder15}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 2.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder25}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder25) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageUnder25}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 3.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder35}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder35) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamHomeStats.percentageUnder35}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  


                                    </Container>   {/**END 49,5% */}

                                {/**SPACE */}

                                    <Container w="49.5%">

                                    {/**SPACE */}

                                    <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Total de jogos ${selectGame.team_visit}`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countGames}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>    

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="18px">{`Total de jogos em casa ${selectGame.team_visit}`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countGames}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                    {/**SPACE */}

                                    <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                        <Container w="50%" >
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="18px">{`Total de jogos fora casa ${selectGame.team_visit}`}</Text>
                                            </Container>
                                        </Container>

                                        <Container w="30%">
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countGames}</Text>
                                            </Container>
                                        </Container>

                                        <Container w="20%">
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                            </Container>                           
                                        </Container>

                                    </Container>   


                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_visit} FT`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamAwayStats.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empates FT`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamAwayStats.percentageDraw) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>    

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitórias ${selectGame.team_visit} em casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamAwayStatsHome.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStatsHome.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_visit} fora casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamAwayStatsAway.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStatsAway.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_visit} em casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamAwayStatsHome.percentageDraw) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStatsHome.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  


                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_visit} fora casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamAwayStatsAway.percentageDraw) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStatsAway.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                            {/**SPACE */}

                            <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Ambas marcam`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countTeamsBothScore}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageTeamsBothScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageTeamsBothScore}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  

                        {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} marca`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countTeamScore}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageTeamScore}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} jogando em casa marca`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countTeamScore}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStatsHome.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStatsHome.percentageTeamScore}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  

                        {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} jogando fora de casa marca`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countTeamScore}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStatsAway.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStatsAway.percentageTeamScore}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  

                        {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Over 0.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver05}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageOver05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver05}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  

                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Over 1.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver15}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageOver15) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver15}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Over 2.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver25}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageOver25) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver25}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Over 3.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver35}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageOver35) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver35}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Under 0.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder05}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageUnder05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageUnder05}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Under 1.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder15}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageUnder15) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageUnder15}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Under 2.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder25}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageUnder25) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageUnder25}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  
                        {/**SPACE */}
                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Under 3.5`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder35}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageUnder35) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageUnder35}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  



                                {/**SPACE */}
                                </Container>
                                                
                                </Container> 

                                <Container w="100%" display="flex" mt="20px" jc="space-between">

                                    <Container w="32.5%">

                                        <BoxHeaderGradient>
                                            <Text fs="30px" col="#316227" fw="bold">{selectGame.team_home}</Text>
                                        </BoxHeaderGradient>

                                        {states.teamHomeStats.lastGamesHomeAndAway.map((item,index)=>{
                                            return(
                                                    <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                        <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                        <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                        <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                        </Box>
                                                    </Container>
                                            )
                                        })}

                                    </Container>

                                    <Container w="32.5%">

                                        <BoxHeaderGradient>
                                            <Text fs="30px" col="#316227" fw="bold">Confrontos diretos</Text>
                                        </BoxHeaderGradient>
                                        {states.teamsHomeAndAwayStats.lastGamesHomeAndAway.map((item,index)=>{
                                            return(
                                                    <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                        <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                        <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                        <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                        </Box>
                                                    </Container>
                                            )
                                        })}

                                    </Container>

                                    <Container w="32.5%">

                                        <BoxHeaderGradient>
                                            <Text fs="30px" col="#316227" fw="bold">{selectGame.team_visit}</Text>
                                        </BoxHeaderGradient>
                                        {states.teamAwayStats.lastGamesHomeAndAway.map((item,index)=>{
                                            return(
                                                    <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                        <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                        <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                        <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                        </Box>
                                                    </Container>
                                            )
                                        })}

                                    </Container>

                                </Container>

                                <Container w="100%" display="flex" mt="20px" jc="space-between">

                                    <Container w="32.5%">

                                        <BoxHeaderGradient>
                                            <Text fs="30px" col="#316227" fw="bold">{selectGame.team_home} jogando em casa</Text>
                                        </BoxHeaderGradient>

                                        {states.teamHomeStatsHome.lastGamesTeamGamingHome.map((item,index)=>{
                                            return(
                                                    <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                        <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                        <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                        <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                        </Box>
                                                    </Container>
                                            )
                                        })}

                                    </Container>

                                    <Container w="32.5%">



                                    </Container>

                                    <Container w="32.5%">

                                        <BoxHeaderGradient>
                                            <Text fs="30px" col="#316227" fw="bold">{selectGame.team_visit} jogando em casa</Text>
                                        </BoxHeaderGradient>
                                        
                                        {states.teamAwayStatsHome.lastGamesTeamGamingHome.map((item,index)=>{
                                            return(
                                                    <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                        <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                        <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                        <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                        </Box>
                                                    </Container>
                                            )
                                        })}

                                    </Container>

                                </Container>     

                            </ContainerCalc>



                        </Container>
            
                    </MainContainer>

            </ContainerMobileHide>


            {/**TEMPLATE MOBILE */}
            <ContainerMobileShow>

                <HeaderMobile>

                    <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                        <BsList color='#fff' size={30}/>
                    </ButtonStyled>

                    <Container w='auto' display='flex' >
                        <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                    </Container>

                    <LogoMobile src='./images/logo.png'/>

                </HeaderMobile>

                <Container display={'flex'}  items="center"  ml='2.5%' w="95%" h="100%" mt='20px'>

                    <BoxChampionship onClick={visibleDrop}>
                        <Container display={'flex'}  items="center" jc="center">
                            <ImgChanpionship src={selectChampions.img} />
                            <Text fw="bold" fs="20px" col='#fff'>{limitCharacters(selectChampions.name, 15)}</Text>
                        </Container>
                    </BoxChampionship>


                    <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                        {
                            CHAMPIONS_SHIP.map(item => (
                                isBoxDropDown &&
                                <Container key={item.id} mt={item.margin}>
                                    <BtnChanpionship onClick={() => handleChampionsShip(item)}>
                                        <ImgChanpionship src={item.img} />
                                        {item.name}
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }
                    </BoxDropDown>

                </Container>               

                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("maximims")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Máximas</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("expressCup")}}>
                            <Text col='#fff' fw='bold' fs='20px'>ExpressCup</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("hour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Horários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("multihour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Multihorários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("confrontations")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Confrontos</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("ranking")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ranking</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>

                        {/**
                         *                         <ButtomMenu onClick={()=>{navigate("/robos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Robôs</Text>
                        </ButtomMenu>
                         * 
                         * 
                         */}


                    </Container>

                </SideBarMobile>

                <ContainerMobileOverflow>

                    <Container w="100%" display="flex" fd="row" jc="center" items="center">

                        {nextGames.map((item, index)=>{
                            return(
                                <BtnNextGamesMobile onClick={()=>{handleGame(item)}} bg={selectGame.time === item.time ? "#316227" : "#4f9d5d"} key={index} mt="20px">
                                    <Text col="#fff" fs='15px' fw="bold">{item.time}</Text>
                                </BtnNextGamesMobile>
                            )
                        })}

                    </Container>

                    <Container w="100%" display ="flex" jc="space-between" items="center" mt='20px'>

                        <Container w="auto">

                            <ImageBandeira w="60px" h="40px" src={`./bandeiras/${selectGame.team_home}.png`}/>

                            <Container display="flex" jc="center" items="center" >
                                <Text fs='13px' col="#fff" fw="bold">{selectGame.team_home}</Text>
                            </Container>

                        </Container>
                        {/**
                        *                            <BtnAmountGames>
                            <Text fs="20px" fw="bold" col="#316227">15</Text>
                        </BtnAmountGames>

                        <BtnGeralStatus>
                            <Text fs="20px" fw="bold" col="#316227">Status Geral</Text>
                        </BtnGeralStatus>

                        <BtnStatusAway>
                            <Text fs="20px" fw="bold" col="#316227">Status Visitante</Text>
                        </BtnStatusAway>
                        * 
                        * 
                        */}
                        <Container w="auto">
                            <Text col="#fff" fs="15px" fw="bold">Resultados de confrontos</Text>
                        </Container>


                        <Container w="auto" mr="0px">

                                <ImageBandeira w="60px" h="40px" src={`./bandeiras/${selectGame.team_visit}.png`}/>

                                <Container display="flex" jc="center" items="center" >
                                    <Text fs='13px' col="#fff" fw="bold">{selectGame.team_visit}</Text>
                                </Container>
                            
                        </Container>

                    </Container>      
                    

                    <BoxHeaderGradient mt="20px" h='40px'>
                        <Text fs="15px" col="#316227" fw="bold">Confrontos diretos</Text>
                    </BoxHeaderGradient>

                    <Container bg="#064E3B" h='40px' display="flex" jc='center' w="100%" items="center" mt='10px'>

                        <InputSearch h='30px' onChange={(e)=>{setFilterGames(e.target.value)}} value={filterGames} placeholder="Digite a quantidade de jogos"/>

                        <ButtonStyled h='30px' onClick={()=>{handleFilterGames()}} w="120px"  fs="20px" ml="10px">
                            <Text fs="20px" col="#fff">Filtrar</Text>
                        </ButtonStyled>
                        {
                            filter &&

                            <ButtonStyled h='30px' bg="#F59E0B" onClick={()=>{clearFilter()}} w="120px"  fs="20px" ml="10px">
                                <Text fs="15px" col="#fff">Limpar</Text>
                            </ButtonStyled>
                        }

                    </Container> 

                    
                    <Container bg="#064E3B" h="10px"></Container>  

                    <Container mt='10px' bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="1px" bbbs="solid" bbbc="#fff">
                        
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="13px">{`Vitória ${selectGame.team_home} FT`}</Text>
                        </Container>
                        
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countResultEndHome}</Text>
                        </Container>

                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeResultEndHome)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeResultEndHome)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeResultEndHome)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeResultEndHome}</Text>
                            </Container>

                        </Container>

                    </Container>                                               

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Empate FT`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countResultEndDraw}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeResultEndDraw)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeResultEndDraw)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeResultEndDraw)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeResultEndDraw}</Text>
                            </Container>

                        </Container>

                    </Container>

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Vitória ${selectGame.team_visit}`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countResultEndAway}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeResultEndAway)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeResultEndAway)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                 <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeResultEndAway)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeResultEndAway}</Text>
                            </Container>

                        </Container>

                    </Container>

                    <Container w="100%" bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Gols ${selectGame.team_home}`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countGoalsHome}</Text>
                        </Container>
                                        

                        <Container w="25%" h="100%" display="flex" jc="center" items="center">
                            <Text col="#fff" fs="15px" fw="bold">{`Média de ${states.teamsHomeAndAwayStats.avaregeGoalsHome} gols`}</Text>
                        </Container>

                     </Container>

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Gols ${selectGame.team_visit}`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countGoalsAway}</Text>
                        </Container>
                                        
                        <Container w="25%" h="100%" display="flex" jc="center" items="center">
                            <Text col="#fff" fs="15px" fw="bold">{`Média de ${states.teamsHomeAndAwayStats.avaregeGoalsAway} gols`}</Text>
                        </Container>

                    </Container>  

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                             <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Casa SIM`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countScoreHomeYes}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreHomeYes}</Text>
                            </Container>

                        </Container>

                    </Container>  

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Casa NÂO`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countScoreHomeNo}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeNo)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeNo)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeNo)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreHomeNo}</Text>
                            </Container>

                        </Container>

                    </Container> 

                    <Container bg="#064E3B" h="30px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Over 1.5`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countOver15}</Text>
                        </Container>
                                        

                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeOver15)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeOver15)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeOver15)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeOver15}</Text>
                            </Container>

                        </Container>

                    </Container>  

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Over 2.5`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                             <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countOver25}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeOver25)} h="30px" rd="5px" mr="10px" display="flex">

                             <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                 {iconBadge(states.teamsHomeAndAwayStats.percentegeOver25)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeOver25)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeOver25}</Text>
                            </Container>

                        </Container>

                    </Container>  

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Under 1.5`}</Text>
                        </Container>
                                                
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countUnder15}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeUnder15)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeUnder15)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeUnder15)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeUnder15}</Text>
                             </Container>

                        </Container>

                    </Container>  

                     <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Under 2.5`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                            <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countUnder25}</Text>
                        </Container>
                                        
                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeUnder25)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                {iconBadge(states.teamsHomeAndAwayStats.percentegeUnder25)}
                            </Container>

                            <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeUnder25)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeUnder25}</Text>
                            </Container>

                        </Container>

                    </Container>  

                    <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff" >
                                            
                        <Container w="50%">
                            <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Ambas Marcam`}</Text>
                        </Container>
                                            
                        <Container w="25%">
                             <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countBothTeamsToScore}</Text>
                        </Container>
                                        

                        <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeBothTeamsToScore)} h="30px" rd="5px" mr="10px" display="flex">

                            <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                 {iconBadge(states.teamsHomeAndAwayStats.percentegeBothTeamsToScore)}
                        </Container>

                         <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeBothTeamsToScore)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeBothTeamsToScore}</Text>
                         </Container>

                    </Container>

                </Container>  

                <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                    <Container w="50%">
                        <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_home} marca gol`}</Text>
                    </Container>
                                            
                    <Container w="25%">
                        <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countScoreHomeYes}</Text>
                    </Container>
                                        
                    <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} h="30px" rd="5px" mr="10px" display="flex">

                        <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                            {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)}
                        </Container>

                        <Container w="70%" h="100%" display="flex" jc="center" items="center">
                            <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreHomeYes)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreHomeYes}</Text>
                        </Container>

                    </Container>

                </Container>  

                <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                    <Container w="50%">
                        <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_visit} marca gol`}</Text>
                    </Container>
                                            
                    <Container w="25%">
                         <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countScoreAwayYes}</Text>
                    </Container>
                                        

                     <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeScoreAwayYes)} h="30px" rd="5px" mr="10px" display="flex">

                        <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                            {iconBadge(states.teamsHomeAndAwayStats.percentegeScoreAwayYes)}
                        </Container>

                        <Container w="70%" h="100%" display="flex" jc="center" items="center">
                            <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeScoreAwayYes)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeScoreAwayYes}</Text>
                        </Container>

                    </Container>

                </Container> 

                <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_home} ou Draw`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countHomeOrDraw}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeHomeOrDraw)} h="30px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeHomeOrDraw)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeHomeOrDraw)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeHomeOrDraw}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_visit} ou Draw`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countAwayOrDraw}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeAwayOrDraw)} h="30px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeAwayOrDraw)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeAwayOrDraw)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeAwayOrDraw}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff">
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_home} ou ${selectGame.team_visit}`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countHomeOrAway}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeHomeOrAway)} h="30px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeHomeOrAway)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeHomeOrAway)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeHomeOrAway}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_home} HT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countHomeHT}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeHomeHT)} h="30px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeHomeHT)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeHomeHT)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeHomeHT}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" bbbw="2px" bbbs="solid" bbbc="#fff" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="15px">{`Draw HT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countDrawHT}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeDrawHT)} h="30px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeDrawHT)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeDrawHT)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeDrawHT}</Text>
                                                </Container>

                                            </Container>

                                        </Container>    

                                        <Container bg="#064E3B" h="40px" display="flex" jc="space-between" items="center" >
                                            
                                            <Container w="50%">
                                                <Text ml="10px" col="#fff" fw="bold" fs="15px">{`${selectGame.team_visit} HT`}</Text>
                                            </Container>
                                            
                                            <Container w="25%">
                                                <Text col="#fff" fw="bold" fs="15px">{states.teamsHomeAndAwayStats.countAwayHT}</Text>
                                            </Container>
                                        

                                            <Container w="25%" bg={bgBadge(states.teamsHomeAndAwayStats.percentegeAwayHT)} h="30px" rd="5px" mr="10px" display="flex">

                                                <Container bbrw="2px" bbrs="solid" bbrc="#fff" w="30%" h="100%" display="flex" jc="center" items="center">
                                                    {iconBadge(states.teamsHomeAndAwayStats.percentegeAwayHT)}
                                                </Container>

                                                <Container w="70%" h="100%" display="flex" jc="center" items="center">
                                                    <Text col={textBadge(states.teamsHomeAndAwayStats.percentegeAwayHT)} fs="15px" fw="bold">{states.teamsHomeAndAwayStats.percentegeAwayHT}</Text>
                                                </Container>

                                            </Container>

                                        </Container> 

                                        <Container w="100%" mt='10px'>

                                            {states.teamsHomeAndAwayStats.lastGamesHomeAndAway.length > 0 &&
                                                <BoxHeaderGradient h='50px'>
                                                <Text fs="30px" col="#316227" fw="bold">Confrontos diretos jogos</Text>
                                                </BoxHeaderGradient>
                                            }


                                            {states.teamsHomeAndAwayStats.lastGamesHomeAndAway.map((item,index)=>{
                                                return(
                                                        <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                            <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                            <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                                <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                            </Box>
                                                        </Container>
                                                )
                                            })}

                                        </Container>

                                        <Container w="100%" mt='10px'>

                                            <BoxHeaderGradient h='50px'>
                                                <Text fs="30px" col="#316227" fw="bold">{selectGame.team_home}</Text>
                                            </BoxHeaderGradient>

                                            {states.teamHomeStats.lastGamesHomeAndAway.map((item,index)=>{
                                                return(
                                                        <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                            <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                            <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                                <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                            </Box>
                                                        </Container>
                                                )
                                            })}

                                        </Container>

                                        <Container w="100%" mt='10px' >

                                            <BoxHeaderGradient h='50px'>
                                                <Text fs="30px" col="#316227" fw="bold">{selectGame.team_visit}</Text>
                                            </BoxHeaderGradient>


                                            {states.teamAwayStats.lastGamesHomeAndAway.map((item,index)=>{
                                                return(
                                                        <Container key={index} bg="rgba(240,240,240,0.5)" h="60px" mt="3px" display="flex" jc="space-between" items="center">
                                                            <Text ml="15px" fs="20px" fw="bold" col="#fff">{convertDateTimeToMinutesSeconds(item).date_time}</Text>
                                                            <Text fs="20px" fw="bold" col="#fff">{item.team_home} {item.scoreboard} {item.team_away}</Text>
                                                            <Box bbbc={item.resultEnd === item.team_home ? '#31C48D' : item.resultEnd === 'Empate' ? '#FACA15' : '#ef4444'}>
                                                                <Text fs="20px" fw="bold" col="#fff">{item.resultEnd === item.team_home ? 'V' : item.resultEnd === "Empate" ? 'E' : 'D'}</Text>
                                                            </Box>
                                                        </Container>
                                                )
                                            })}

                                        </Container>

                                <Container w="100%" mt="20px">
                                    <Text col="#fff" fs="30px" >Resultados de confrontos indiretos</Text>
                                </Container>


                                    <Container w="100%" >

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center" >
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Total de jogos ${selectGame.team_home}`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countGames}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>    

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                        
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="18px">{`Total de jogos em casa ${selectGame.team_home}`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countGames}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                    {/**SPACE */}

                                    <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                        
                                        <Container w="50%" >
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="18px">{`Total de jogos fora casa ${selectGame.team_home}`}</Text>
                                            </Container>
                                        </Container>

                                        <Container w="30%">
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countGames}</Text>
                                            </Container>
                                        </Container>

                                        <Container w="20%">
                                            <Container display="flex" jc="center" items="center">
                                                <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                            </Container>                           
                                        </Container>

                                    </Container>   


                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_home} FT`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empates FT`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageDraw)> 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>    

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitórias ${selectGame.team_home} em casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsHome.percentageVictory)> 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStatsHome.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                    {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_home} fora casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countGamesVictory}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsAway.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStatsAway.percentageVictory}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>   

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_home} em casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageDraw )> 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStatsHome.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  


                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_home} fora casa`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countGamesDraw}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStatsAway.percentageDraw}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                    
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Ambas marcam`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countTeamsBothScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageTeamsBothScore) > 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStats.percentageTeamsBothScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} marca`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countTeamScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageTeamScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} jogando em casa marca`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsHome.countTeamScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsHome.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStatsHome.percentageTeamScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_home} jogando fora de casa marca`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStatsAway.countTeamScore}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStatsAway.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamHomeStatsAway.percentageTeamScore}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}

                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 0.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver05}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageOver05}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 1.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver15}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver15) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageOver15}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 2.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver25}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver25) > 50 ? '#84E1BC' : '#F05252'}fw="bold" fs="20px">{states.teamHomeStats.percentageOver25}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Over 3.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countOver35}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageOver35) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageOver35}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 0.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder05}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageUnder05}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 1.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder15}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder15) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageUnder15}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 2.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder25}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder25) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamHomeStats.percentageUnder25}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  
                                {/**SPACE */}
                                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                                            
                                            <Container w="50%" >
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{`Under 3.5`}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="30%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col="#fff" fw="bold" fs="20px">{states.teamHomeStats.countUnder35}</Text>
                                                </Container>
                                            </Container>

                                            <Container w="20%">
                                                <Container display="flex" jc="center" items="center">
                                                    <Text col={parseFloat(states.teamHomeStats.percentageUnder35) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamHomeStats.percentageUnder35}</Text>
                                                </Container>                           
                                            </Container>

                                        </Container>  

                                   {/**SPACE */}

                                   <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                    
                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Total de jogos ${selectGame.team_visit}`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countGames}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>    

                            {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="18px">{`Total de jogos em casa ${selectGame.team_visit}`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countGames}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>   

                            {/**SPACE */}

                            <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                <Container w="50%" >
                                    <Container display="flex" jc="center" items="center">
                                        <Text col="#fff" fw="bold" fs="18px">{`Total de jogos fora casa ${selectGame.team_visit}`}</Text>
                                    </Container>
                                </Container>

                                <Container w="30%">
                                    <Container display="flex" jc="center" items="center">
                                        <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countGames}</Text>
                                    </Container>
                                </Container>

                                <Container w="20%">
                                    <Container display="flex" jc="center" items="center">
                                        <Text col="#fff" fw="bold" fs="20px">{}</Text>
                                    </Container>                           
                                </Container>

                            </Container>   


                            {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_visit} FT`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countGamesVictory}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageVictory}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>

                            {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Empates FT`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countGamesDraw}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStats.percentageDraw) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageDraw}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>    

                            {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Vitórias ${selectGame.team_visit} em casa`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countGamesVictory}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStatsHome.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStatsHome.percentageVictory}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>   

                            {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Vitória ${selectGame.team_visit} fora casa`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countGamesVictory}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStatsAway.percentageVictory) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStatsAway.percentageVictory}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>   

                        {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_visit} em casa`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countGamesDraw}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStatsHome.percentageDraw) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStatsHome.percentageDraw}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  


                        {/**SPACE */}

                                <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">

                                    <Container w="50%" >
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{`Empate ${selectGame.team_visit} fora casa`}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="30%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countGamesDraw}</Text>
                                        </Container>
                                    </Container>

                                    <Container w="20%">
                                        <Container display="flex" jc="center" items="center">
                                            <Text col={parseFloat(states.teamAwayStatsAway.percentageDraw) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStatsAway.percentageDraw}</Text>
                                        </Container>                           
                                    </Container>

                                </Container>  

                    {/**SPACE */}

                    <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Ambas marcam`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countTeamsBothScore}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageTeamsBothScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageTeamsBothScore}</Text>
                                </Container>                           
                            </Container>

                        </Container>  

                {/**SPACE */}

                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} marca`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countTeamScore}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageTeamScore}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}

                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} jogando em casa marca`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsHome.countTeamScore}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStatsHome.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStatsHome.percentageTeamScore}</Text>
                                </Container>                           
                            </Container>

                        </Container>  

                {/**SPACE */}

                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`${selectGame.team_visit} jogando fora de casa marca`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStatsAway.countTeamScore}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStatsAway.percentageTeamScore) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStatsAway.percentageTeamScore}</Text>
                                </Container>                           
                            </Container>

                        </Container>  

                {/**SPACE */}

                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Over 0.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver05}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageOver05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver05}</Text>
                                </Container>                           
                            </Container>

                        </Container>  

                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Over 1.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver15}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageOver15) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver15}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Over 2.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver25}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageOver25) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver25}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Over 3.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countOver35}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageOver35) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageOver35}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Under 0.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder05}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageUnder05) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageUnder05}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Under 1.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder15}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageUnder15) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageUnder15}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Under 2.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder25}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageUnder25) > 50 ? '#84E1BC' : '#F05252'}  fw="bold" fs="20px">{states.teamAwayStats.percentageUnder25}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                {/**SPACE */}
                        <Container h="50px" bbbw="2px" bbbc="#fff" bbbs="solid" display="flex" jc="space-between" items="center">
                            
                            <Container w="50%" >
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{`Under 3.5`}</Text>
                                </Container>
                            </Container>

                            <Container w="30%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col="#fff" fw="bold" fs="20px">{states.teamAwayStats.countUnder35}</Text>
                                </Container>
                            </Container>

                            <Container w="20%">
                                <Container display="flex" jc="center" items="center">
                                    <Text col={parseFloat(states.teamAwayStats.percentageUnder35) > 50 ? '#84E1BC' : '#F05252'} fw="bold" fs="20px">{states.teamAwayStats.percentageUnder35}</Text>
                                </Container>                           
                            </Container>

                        </Container>  
                                    </Container>   {/**END 100% Container */}





                <Container bg="#064E3B" h="10px"></Container>  


                </ContainerMobileOverflow>




            </ContainerMobileShow>

            {modalOdds === true && 
                <ModalOdds 
                game={gameOddsSelect} 
                date={dateOddsSelect} 
                setModalOdds={setModalOdds}
                url={selectChampions.id}
            />}
        
        </GlobalContainer>
    )
}

